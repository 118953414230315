import * as React from "react";
import { graphql, useStaticQuery, Link } from "gatsby";
import dayjs from "dayjs";

import SEO from "../components/seo";
import Layout from "../components/layout";
import BlogPost from "../components/blog-post";

import banner from "../../static/banner.png";

const WORDS_PER_MINUTE = 265;

const Blog = () => {
  const data = useStaticQuery(graphql`
    query {
      allMarkdownRemark(
        filter: { fileAbsolutePath: { regex: "content/(post)/" } }
      ) {
        nodes {
          id
          wordCount {
            words
          }
          frontmatter {
            title
            author
            date
            slug
            tags
            description
            featured_image
          }
        }
      }
      authorsList: allMarkdownRemark(
        filter: { fileAbsolutePath: { regex: "content/(authors)/" } }
      ) {
        nodes {
          frontmatter {
            name
            avatar
          }
        }
      }
    }
  `);

  const sortDates = (a, b) => {
    const first = a.frontmatter.date;
    const second = b.frontmatter.date;

    if (dayjs(first).diff(second) > 0) {
      return -1;
    }
    if (dayjs(first).diff(second) < 0) {
      return 1;
    }
    return 0;
  };

  const { nodes } = data.allMarkdownRemark;
  const { nodes: authorsList } = data.authorsList;
  const sortedNodes = nodes.sort(sortDates);

  // TEMP
  return (
    <Layout>
      <main className="max-w-container flex flex-col space-y-10 py-20 px-10 lg:px-0 mx-auto">
        <h2 className="mb-8 w-full text-center">
          <span className="text-red">Petro Ninja</span> Blog
        </h2>
        <h5 className="mb-8 w-full text-center">Coming soon!</h5>
      </main>
    </Layout>
  );

  return (
    <>
      <SEO title="Blog" />
      <Layout>
        <main className="max-w-container flex flex-col space-y-10 py-20 px-10 lg:px-0 mx-auto">
          <h2 className="mb-8 w-full text-center">
            <span className="text-red">Petro Ninja</span> Blog
          </h2>
          <div className="lg:px-20">
            {sortedNodes.map((node) => (
              <BlogPost data={node} authorsList={authorsList} />
            ))}
          </div>
        </main>
      </Layout>
    </>
  );
};

export default Blog;
