import React from "react";
import { Link } from "gatsby";
import dayjs from "dayjs";

import banner from "../../static/banner.png";
import "../styles/blog.css";

const WORDS_PER_MINUTE = 265;

const BlogPost = ({ data, authorsList }) => {
  const { id, frontmatter, wordCount } = data;
  const { title, tags, date, slug, description, author, featured_image } =
    frontmatter;
  const timeToRead = Math.ceil(wordCount.words / WORDS_PER_MINUTE);

  const articleAuthor = authorsList.find(
    (el) => el.frontmatter.name === author
  );

  const image = frontmatter.featured_image
    ? frontmatter.featured_image
    : banner;

  const fixPath = (path) => {
    return path.toLowerCase().replace(' ', '-')
  }

  return (
    <Link to={`/blog/${slug}`} className="hover:no-underline">
      <div className="mt-4 pb-6 lg:mt-8 lg:pb-8 border-b border-ecree lg:grid lg:grid-cols-blog-post gap-12">
        <div className="h-full flex items-center">
          <img className="mb-4" src={image} />
        </div>

        <div className="h-full flex flex-col justify-center">
          {tags &&
            tags.map((tag) => (
              <div className="uppercase text-red mb-1">{tag}</div>
            ))}
          <div>
            <div className="text-xl font-normal text-black mb-2">{title}</div>
            <div className="mb-2">{description}</div>
            <div className="blog">
              <Link
                to={`/blog/author/${fixPath(articleAuthor.frontmatter.name)}`}
                className="no-underline"
              >
                <div className="flex items-center mt-4 mb-2">
                  <div>
                    <img
                      className="avatar"
                      src={articleAuthor.frontmatter.avatar}
                    />
                  </div>
                  <div>
                    <div className="text-gray">
                      {articleAuthor.frontmatter.name}
                    </div>
                    <div className="text-gray text-sm">
                      {dayjs(date).format("DD MMM YYYY")}
                      <span className="mx-2">•</span>
                      <span>{timeToRead} min read</span>
                    </div>
                  </div>
                </div>
              </Link>
            </div>
          </div>
        </div>
      </div>
    </Link>
  );
};

export default BlogPost;
